import { Button } from 'antd'

// import { ReloadOutlined } from '@ant-design/icons'

import './paywalls/IntentSignalsPermanentPaywall.scss'

interface errorMessageProps {
  caption?: any
  subCaption?: any
  onClick?: () => void
  buttonText?: string
}

const ErrorMessage = ({
  caption = `Something went wrong...`,
  subCaption,
  onClick,
  buttonText = `Refresh`,
}: errorMessageProps) => {
  const handleClick = onClick || (() => window.location.reload())

  return (
    <div className='paywall-modal'>
      <h1>{caption}</h1>
      {subCaption && <h4 style={{ margin: '0px', textAlign: 'center' }}>{subCaption}</h4>}
      <Button className='mt-3' type='primary' onClick={handleClick}>
        {buttonText}
      </Button>
    </div>
  )
}

export default ErrorMessage
