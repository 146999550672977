import React, { useState, useEffect } from 'react'
import { Button, message } from 'antd'
import contacts_icon from 'common/assets/svg/leads_contacts_icon.svg'
import list_icon from 'common/assets/svg/leads_list_icon.svg'
import csv_icon from 'common/assets/svg/csv.svg'
import { useAuth } from 'common/hooks/useAuth.hooks'

import tg_icon from 'common/assets/svg/leads_tg_icon.svg'
// import leads_dummy_image from 'common/assets/svg/leads_leads_dummy_image.svg'
import ImportLeadsFromListsModal from './Modals/ImportLeadsFromListsModal'
import ImportTelegramContactsModal from './Modals/ImportTelegramContactsModal'
import ImportLeadsFromCsvModal from './Modals/ImportLeadsFromCsvModal'
import {
  useAddLeadsMutation,
  useGetAllRawLeadsQuery,
  useLazySetCampaignRecipientsQuery,
  useLazySetRecipientsFromCsvQuery,
  // useLazySetRecipientsFromGroupsQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { useAppDispatch, useAppSelector } from 'state'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'
import { post } from 'common/api/axios'

import styles from './Leads.module.scss'
import LeadsTable from './LeadsTable'
import { SET_IMPORTED_LEADS } from '../../state/outreachCampaignSlice'
import OutOfPointsUseRemaining from 'common/components/OutOfPoints/OutOfPointsUseRemaining'
// import ImportLeadsFromGroupsModal from './Modals/ImportLeadsFromGroupsModal'
// import { useLazyCheckStatusGroupsMembersQuery } from 'common/components/SocialConnection/state/api/SocialConnectionApi'
import NewImportLeadsFromGroupsModal from '../Components/CreateCampaign/Group/NewImportLeadsFromGroupsModal'
import { useLazyGetUserInTeamQuery } from 'common/api/UserApi'
import ImportLeadsFromTelegramModal from '../Components/CreateCampaign/Group/ImportLeadsFromTelegramModal'

interface LeadsProps {
  page: number
  setPage: (page: number) => void
  current: number
  setCurrent: (current: number) => void
}

const Leads: React.FC<LeadsProps> = ({ page, setPage, current, setCurrent }) => {
  // const [checkStatusGroupsMembers] = useLazyCheckStatusGroupsMembersQuery()
  const [importFromListsOpen, setImportFromListsOpen] = useState(false)
  const [importFromCsvOpen, setImportFromCsvOpen] = useState(false)
  const [leadsTableData, setLeadsTableData] = useState<any>()
  const { userDetails } = useAuth()
  const [costPoints, setCostPoints] = useState(0)
  const [openAddGroupsModal, setOpenAddGroupsModal] = useState(false)
  const [importFromTeleGramOpen, setImportFromTeleGramOpen] = useState(false)
  const [isUserInATeam, setIsUserInATeam] = useState(false)
  const [getUserInTeam] = useLazyGetUserInTeamQuery()

  const [importTelegramOpen, setImportTelegramOpen] = useState(false)
  const { campaign_id, campaign_name, imported_leads, selected_platform } = useAppSelector(
    (state) => state.outreachCampaign,
  )
  const [currentStep, setCurrentStep] = useState(imported_leads ? 1 : 0)
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [outOfPoints, setOutOfPoints] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCSV, setIsLoadingCSV] = useState(false)
  const [isLoadingGroups, setIsLoadingGroups] = useState(false)
  // const [useRemainingPointsFlag, setUseRemainingPointsFlag] = useState(false)
  const [lastOperation, setLastOperation] = useState<any>({
    func: null,
    params: null,
    name: null,
  })
  const dispatch = useAppDispatch()

  // const [name, setName] = useState(campaign_name)

  // useEffect(() => {
  //   setName(campaign_name)
  // }, [campaign_name])

  const [refetcSetRecipientsFromCsv, { data: campaignRecipientsCsvData }] = useLazySetRecipientsFromCsvQuery()
  // const [setRecipientsFromGroups] = useLazySetRecipientsFromGroupsQuery()
  // const [setRecipientsFromGroups, { data: campaignRecipientsGroupsData }] = useLazySetRecipientsFromGroupsQuery()

  const [refetcSetCampaignRecipients, { data: campaignRecipientsData }] = useLazySetCampaignRecipientsQuery()
  const [addLeadsMutation] = useAddLeadsMutation()

  const [excludeLeadsInOtherCampaigns, setExcludeLeadsInOtherCampaigns] = useState(true)
  const [exclusePreviouslySent, setExclusePreviouslySent] = useState(true)
  const [excludeTeam, setExcludeTeam] = useState(true)
  const [excludeTeamSent, setExcludeTeamSent] = useState(true)

  const [leadIds, setLeadIds] = useState<any>()
  const { data: allLeadIds } = useGetAllRawLeadsQuery(
    {
      getAll: true,
    },
    {
      skip: leadIds,
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (allLeadIds) {
      setLeadIds(allLeadIds)
    }
  }, [allLeadIds])

  useEffect(() => {
    setLeadsTableData(campaignRecipientsCsvData)
  }, [campaignRecipientsCsvData])

  useEffect(() => {
    setLeadsTableData(campaignRecipientsData)
  }, [campaignRecipientsData])

  // useEffect(() => {
  //   setLeadsTableData(campaignRecipientsGroupsData)
  // }, [campaignRecipientsGroupsData])

  const handleImportFromLists = () => {
    post('/log', {
      action: 'Import Leads From Lists',
      data: { campaign_id, campaign_name },
    })
    setImportFromListsOpen(true)
  }

  const handleImportFromCSV = () => {
    post('/log', {
      action: 'Import Leads From CSV',
      data: { campaign_id, campaign_name },
    })
    setImportFromCsvOpen(true)
  }

  const handleImportGroups = () => {
    setOpenAddGroupsModal(true)
  }

  const handleOnFinishImportFromLists = async (
    contactsListIds: any,
    credits: any,
    allCntactsToggle: any,
    failedCntactsToggle: any,
    excludeTeam: boolean,
    excludeTeamSent: boolean,
    useRemainingPointsFlag?: any,
  ) => {
    setIsLoading(true)
    setLastOperation({
      func: handleOnFinishImportFromLists,
      params: {
        contactsListIds,
        credits,
        allCntactsToggle,
        failedCntactsToggle,
        excludeTeam,
        excludeTeamSent,
        useRemainingPointsFlag,
      },
      name: 'handleOnFinishImportFromLists',
    })
    const response = await refetcSetCampaignRecipients({
      contactsListIds,
      campaign_id,
      campaign_name,
      credits,
      allCntactsToggle,
      failedCntactsToggle,
      excludeTeam,
      excludeTeamSent,
      useRemainingPoints: useRemainingPointsFlag || false,
      selected_platform,
    })

    if (response?.error?.status === 470) {
      setCostPoints(response?.error?.data?.cost_point)
      setOutOfPoints(true)
    } else {
      setImportFromListsOpen(false)
      setCurrentStep(1)
      dispatch(SET_IMPORTED_LEADS(true))
    }
    setIsLoading(false)
  }

  const handleOnFinishImportTelegram = () => {
    setImportTelegramOpen(false)
    setCurrentStep(1)
  }

  const handleOnFinishImportFromCSV = async (campaign_id_tmp: any, campaign_name_tmp: any, csvData: any) => {
    setIsLoadingCSV(true)
    const response = await refetcSetRecipientsFromCsv({
      campaign_id,
      campaign_name,
      csvData,
      exclusePreviouslySent,
      excludeLeadsInOtherCampaigns,
      excludeTeam,
      excludeTeamSent,
      selected_platform,
    })

    if (response?.error?.status === 470) {
      setOutOfCredits(true)
    }
    setImportFromCsvOpen(false)
    setCurrentStep(1)
    dispatch(SET_IMPORTED_LEADS(true))
    setIsLoadingCSV(false)
  }

  // const pollOperationStatus = async (operationId: any, maxRetries = 50, ms = 15000) => {
  //   let attempts = 0
  //   return new Promise((resolve, reject) => {
  //     const interval = setInterval(async () => {
  //       if (attempts >= maxRetries) {
  //         clearInterval(interval)
  //         reject(new Error('Max retries reached'))
  //         return
  //       }
  //       // operationStatusGroupsMembers
  //       try {
  //         const statusResponse = await checkStatusGroupsMembers({ operationId })
  //         const { status, message } = statusResponse.data
  //         if (status !== 'processing') {
  //           clearInterval(interval)
  //           // setSignInStatus(message)
  //           resolve(message) // Resolve with the final message
  //         }
  //       } catch (error) {
  //         clearInterval(interval)
  //         reject(error) // Reject with the error
  //       }
  //       attempts++
  //     }, ms) // Poll every 5 seconds
  //   })
  // }

  // const handleOnFinishImportFromGroups = async (data: any, useRemainingPointsFlag?: any) => {
  //   setIsLoadingGroups(true)
  //   setLastOperation({
  //     func: handleOnFinishImportFromGroups,
  //     params: {
  //       campaign_id,
  //       campaign_name,
  //       selected_platform,
  //       ...data,
  //       useRemainingPointsFlag,
  //     },
  //     name: 'handleOnFinishImportFromGroups',
  //   })
  //   const response = await setRecipientsFromGroups({
  //     campaign_id,
  //     campaign_name,
  //     ...data,
  //     selected_platform,
  //     useRemainingPoints: useRemainingPointsFlag || false,
  //   })

  //   if (response?.error?.status === 470) {
  //     setCostPoints(response?.error?.data?.cost_point)
  //     setOutOfPoints(true)
  //   } else {
  //     //response.operationId
  //     const recipientsFromGroupsData = await pollOperationStatus(response.data.opId)
  //     setLeadsTableData(recipientsFromGroupsData)

  //     setOpenAddGroupsModal(false)
  //     setCurrentStep(1)
  //     dispatch(SET_IMPORTED_LEADS(true))
  //   }
  //   setIsLoadingGroups(false)
  // }

  const retryWithRemainingPoints = () => {
    if (lastOperation.name === 'handleOnFinishImportFromLists') {
      lastOperation.func(
        lastOperation.params.contactsListIds,
        lastOperation.params.credits,
        lastOperation.params.allCntactsToggle,
        lastOperation.params.failedCntactsToggle,
        lastOperation.excludeTeam,
        lastOperation.excludeTeamSent,
        true,
      )
    } else if (lastOperation.name === 'handleOnFinishImportFromCSV') {
      lastOperation.func(
        lastOperation.params.campaign_id,
        lastOperation.params.campaign_name,
        lastOperation.params.csvData,
      )
    } else if (lastOperation.name === 'handleOnFinishImportFromGroups') {
      lastOperation.func(lastOperation.params, true)
    }
    setOutOfPoints(false)
  }

  const handleAddLeads = async (body: any) => {
    setIsLoadingGroups(true)
    try {
      const response = await addLeadsMutation(body)

      if (response?.error?.status === 470) {
        setCostPoints(response?.error?.data?.cost_point)
        setOutOfPoints(true)
      } else {
        //response.operationId
        setLeadsTableData(response)
        setOpenAddGroupsModal(false)
        setCurrentStep(1)
        dispatch(SET_IMPORTED_LEADS(true))
      }
    } catch (error) {
      console.error(error)
      message.error('Error when add leads')
    }
    setIsLoadingGroups(false)
  }

  useEffect(() => {
    const getTeam = async () => {
      const { data } = await getUserInTeam()

      if (data) {
        setIsUserInATeam(data)
      }
    }
    getTeam()
  }, [])

  return (
    <>
      {currentStep === 0 && (
        <div className={styles.container}>
          <div className={styles.centered}>
            <img src={contacts_icon} alt='' />
            <p>Add Leads To Your Campaign</p>
            <span>Import the campaign leads from your Convrt lists or your Telegram account</span>
            <div className={styles.buttons}>
              <Button type='primary' onClick={handleImportFromLists}>
                <div className={styles.button}>
                  <img src={list_icon} alt='' />
                  <span> Import Leads From Lists</span>
                </div>
              </Button>
              <Button
                className={styles.buttonCSV}
                type='primary'
                onClick={handleImportFromCSV}
                disabled={!userDetails.addons.campaign_import_csv}>
                <div className={styles.button}>
                  <img src={csv_icon} alt='' />
                  <span> Import CSV File</span>
                </div>
              </Button>
              {selected_platform !== 'linkedin' && (
                <Button type='primary' onClick={handleImportGroups}>
                  <div>
                    <div className={styles.button}>
                      <img src={tg_icon} alt='' />
                      <span>Import from Engagement Hub</span>
                      <label>NEW</label>
                    </div>
                  </div>
                </Button>
              )}

              {selected_platform !== 'linkedin' && (
                <Button
                  type='primary'
                  onClick={() => setImportFromTeleGramOpen(true)}
                  disabled={!leadIds || !leadIds.total}>
                  <div>
                    <div className={styles.button}>
                      <span>Import from My telegram</span>
                      <label>NEW</label>
                    </div>
                  </div>
                </Button>
              )}
              {/* <Tooltip title='Coming Soon!' placement='bottom'>
                <Button type='primary' onClick={handleImportTelegram} disabled={true}>
                  <div>
                    <div className={styles.button}>
                      <img src={tg_icon} alt='' />
                      <span>Import Telegram Contacts</span>{' '}
                    </div>
               
                  </div>
                </Button>
              </Tooltip> */}
            </div>
            <ImportLeadsFromListsModal
              open={importFromListsOpen}
              onClose={() => setImportFromListsOpen(false)}
              onFinish={handleOnFinishImportFromLists}
              isLoading={isLoading}
              isUserInATeam={isUserInATeam}
            />
            <ImportTelegramContactsModal
              open={importTelegramOpen}
              onClose={() => setImportTelegramOpen(false)}
              onFinish={handleOnFinishImportTelegram}
            />
            <ImportLeadsFromCsvModal
              open={importFromCsvOpen}
              onClose={() => setImportFromCsvOpen(false)}
              onFinish={handleOnFinishImportFromCSV}
              isLoading={isLoadingCSV}
              setExcludeLeadsInOtherCampaigns={setExcludeLeadsInOtherCampaigns}
              setExclusePreviouslySent={setExclusePreviouslySent}
              excludeLeadsInOtherCampaigns={excludeLeadsInOtherCampaigns}
              exclusePreviouslySent={exclusePreviouslySent}
              setExcludeTeam={setExcludeTeam}
              setExcludeTeamSent={setExcludeTeamSent}
              excludeTeam={excludeTeam}
              excludeTeamSent={excludeTeamSent}
              isUserInATeam={isUserInATeam}
            />
            {/* <ImportLeadsFromGroupsModal
              open={openAddGroupsModal}
              onClose={() => setOpenAddGroupsModal(false)}
              onConfirm={handleOnFinishImportFromGroups}
              isLoading={isLoadingGroups}
            /> */}

            <NewImportLeadsFromGroupsModal
              open={openAddGroupsModal}
              onClose={() => {
                setOpenAddGroupsModal(false)
              }}
              onConfirm={handleAddLeads}
              campaign_id={campaign_id}
              isLoading={isLoadingGroups}
              isUserInATeam={isUserInATeam}
            />

            <ImportLeadsFromTelegramModal
              open={importFromTeleGramOpen}
              onClose={() => {
                setImportFromTeleGramOpen(false)
              }}
              onConfirm={handleAddLeads}
              campaign_id={campaign_id}
              isLoading={isLoadingGroups}
              isUserInATeam={isUserInATeam}
            />
          </div>

          {/* <LeadsPage /> */}
        </div>
      )}
      {currentStep === 1 && (
        <LeadsTable
          data={leadsTableData ? leadsTableData?.response : []}
          campaign_id={campaign_id}
          campaign_name={campaign_name}
          page={page}
          setPage={setPage}
          current={current}
          setCurrent={setCurrent}
        />
      )}
      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={retryWithRemainingPoints}
          cost_points={costPoints}
        />
      )}
      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          type='limit'
          creditType={'Contact'}
          caption={'CSV import'}
          // location = {'campaign'}
        />
      )}
    </>
  )
}

export default Leads
