import { useEffect, useState } from 'react'
import cx from 'classnames'
import { ConfigProvider, FloatButton, Layout } from 'antd'
import { Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom'

import { SideFilter } from 'common/components/SideFilter/SideFilter'
import { TopBar } from 'common/components/TopBar/TopBar'
import { useAuth } from 'common/hooks/useAuth.hooks'
// eslint-disable-next-line import-helpers/order-imports
import Sidebar from './common/components/sidebar/sidebar'

import Login from 'features/login/login'
import SignUpPage from 'features/sign-up/sign-up'
import ResetPasswordPage from 'features/sign-up/reset-password'
import ContactsPage from 'features/contacts/contacts'
import IntentSignalsPage from 'features/intent-signals/intent-signals'

import MobileLayout from 'common/layouts/MobileLayout'
import { useAppSelector } from 'state'
import './App.css'
import './antd-overwrites.scss'
import '/node_modules/primeflex/primeflex.css'

import 'swiper/css'
import 'swiper/css/navigation'
// import EventsPage from './features/events/events'
import { ErrorBoundary } from 'react-error-boundary'
import CompanyAdminPanel from './features/company-admin-pannel/company-admin-pannel'
// import TrialFunnel from 'common/components/TrialFunnel/TrialFunnel'
import { Userpilot } from 'userpilot'
import ConvrtTabs from 'common/components/TopBar/ConvrtTabs'
// import BuyConvrtModal from 'common/components/BuyConvrt/BuyConvrtModal'
import BuyConvrt from 'common/components/BuyConvrt/BuyConvrt'
// import ThankYouPage from 'common/components/BuyConvrt/ThankYouPage'
import SignupRegistrationCode from 'features/sign-up/signupRegistrationCode'
// import OutreachMainPage from 'common/components/OutreachCampaign/OutreachMainPage'
import NewCampaign from 'common/components/OutreachCampaign/NewCampaign/NewCampaign'
import EditCampaign from 'common/components/OutreachCampaign/EditCampaign/EditCampaign'
// import Exposed from 'features/Outreach/Exposed'
import ExposedContacts from 'features/Outreach/ExposedContacts'
import Settings from 'features/settings/SettingsPage'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { setIsSubscriptionDaysPassed } from 'state/filtersSlice/filtersSlice'
import { useAppDispatch } from 'state'

import { useIntercom } from 'react-use-intercom'
import Chat from 'common/components/Chat/Chat'
import SignupSuccess from 'features/sign-up/SignupSuccess'
import Campaigns from 'common/components/OutreachAI/HomePage/Campaigns'
import { ImportCustomListMyContact } from 'features/Outreach/components/ImportCustomList'
import ImportTelegramContacts from 'features/Outreach/ImportTelegramContacts'
import RawLeads from 'features/Outreach/RawLeads'
import MatchedLeads from 'features/Outreach/MatchedLeads'
import {
  SET_ARTICLE_OPEN,
  SET_FLOAT_VISIBLE,
  SET_GETTING_STARTED_OPEN,
  SET_INTERCOM_OPEN,
  SET_LEARNING_OPEN,
} from 'common/components/OutreachAI/HomePage/LearningCenter/state/slice/learningSlice'
import { QuestionOutlined } from '@ant-design/icons'
import LearningCenter from 'common/components/OutreachAI/HomePage/LearningCenter/LearningCenter'
import { SET_OUT_OF_OUTREACH } from 'GeneralSlice'
import NotificationStrip from 'GeneralComponents/NotificationStrip'
// import CheckoutPage from 'common/components/BuyConvrt/CheckoutPage'
// import { FilterBar } from 'common/components/FilterBar/FilterBar'
// import OutreachBanner from 'common/components/TrialBanner/OutreachBanner'
// import { useShowStartScreen } from 'common/providers/ShowStartScreenProvider'
// import { useActiveFilters } from 'common/hooks/useFilters'

const { Content } = Layout

const App = () => {
  const { boot, shutdown, isOpen: isIntercomOpen, update } = useIntercom()
  const { isSidebarOpen } = useAppSelector((state) => state.filters)
  const locationPathName = useLocation().pathname.split('/')[1]
  const { user, logout, userDetails, refetchUserLite, isBetaOrTrialUser, isPaidTrialUser } = useAuth()
  const [shouldHideCheckout, setShouldHideCheckout] = useState(userDetails?.flag)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [userData, setUserData] = useState(userDetails)
  const [outOfOutreach, setOutOfOutreach] = useState(false)
  const {
    floatVisible,
    learningOpen,
    articleOpen,
    gettingStartedOpen,
    intercomOpen,
    articleSeeMoreOpen,
    videoSeeMoreOpen,
  } = useAppSelector((state) => state.learning)
  const dispatch = useAppDispatch()

  const bootIntercom = () =>
    boot({
      name: userDetails?.name,
      email: userDetails?.email,
    })

  // const [activeFilters, setActiveFilters] = useActiveFilters()
  // const [openBanner, setOpenBanner] = useState(true)

  const navigate = useNavigate()
  const notForLearning = ['login', 'signup', 'reset-password', 'premium-signup', 'unifiedInbox']
  const currentDate = new Date()
  const createdSubscriptionDate = new Date(userDetails?.subscription_created_at)
  const subscriptionDays = userDetails?.subscription_days
  const timeDifference = currentDate?.getTime() - createdSubscriptionDate?.getTime()
  const daysPassed = timeDifference / (1000 * 60 * 60 * 24)
  const navToCheckout =
    !userDetails?.flag && (isBetaOrTrialUser || isPaidTrialUser) && Math.trunc(subscriptionDays - daysPassed) <= 0

  const showSidebar =
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/cs-login' &&
    !window.location.pathname.startsWith('/signup') &&
    !window.location.pathname.startsWith('/reset-password') &&
    !window.location.pathname.startsWith('/premium-signup/')

  const showTopBar =
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/cs-login' &&
    !window.location.pathname.startsWith('/signup') &&
    !window.location.pathname.startsWith('/reset-password') &&
    !window.location.pathname.startsWith('/premium-signup/') &&
    !window.location.pathname.startsWith('/settings') &&
    !window.location.pathname.startsWith('/unifiedInbox') &&
    window.location.pathname !== '/checkout' &&
    window.location.pathname !== '/checkout/success' &&
    !window.location.pathname.startsWith('/outreach') &&
    !window.location.pathname.startsWith('/exposedContacts') &&
    !window.location.pathname.startsWith('/importTelegramContacts')

  dispatch(setIsSubscriptionDaysPassed(navToCheckout))

  useEffect(() => {
    if (!user) {
      const path = window.location.pathname.split('/')[1]
      const query = window.location.search
      switch (path) {
        case 'signup':
          if (window.location.pathname === '/signup/success') {
            // navigate('/signup/success')
          } else {
            navigate('/signup')
          }
          return
        case 'premium-signup':
          return
        case 'reset-password':
          return
        case 'login':
          if (query?.includes('email')) {
            navigate(`${window.location.pathname}${query}`)
          } else {
            navigate('/login')
          }
          return
        case 'cs-login':
          navigate('/cs-login')
          return
        default:
          navigate('/login')
          return
      }
    } else if (navToCheckout) {
      if (window.location.pathname !== '/checkout') {
        navigate('/checkout')
      }
    } else if (user) {
      Userpilot.identify(userDetails.id, {
        name: userDetails.name,
        email: userDetails.email,
        created_at: userDetails.subscription_created_at,
      })
      bootIntercom()
    } else if (user && window.location.pathname === '/') {
      navigate(`/companies`)
    }
  }, [user])

  const handleError = () => {
    if (process.env.NODE_ENV === 'development') return
    shutdown()
    logout()
    window.location.reload()
  }

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setShouldHideCheckout(data.flag)
      setUserData(data)

      const outreachCredits = data?.credits?.contacts_outreached_left
      if (!outreachCredits || outreachCredits <= 0) {
        setOutOfOutreach(true)
        dispatch(SET_OUT_OF_OUTREACH(true))
      } else {
        setOutOfOutreach(false)
        dispatch(SET_OUT_OF_OUTREACH(false))
      }
    }

    if (user) {
      getUserDetails()

      const intervalId = setInterval(() => {
        getUserDetails()
      }, 30000)

      return () => clearInterval(intervalId)
    }
  }, [user])

  useEffect(() => {
    localStorage.removeItem('imported_telegram')
    let importedAccount = null
    if (userDetails?.is_imported_telegram) return
    if (userDetails && userDetails.outreach && userDetails.outreach.length) {
      importedAccount = userDetails.outreach.find((item: any) => item.import_at)
    }
    if (userDetails && userDetails.connected_telegrams && userDetails.connected_telegrams.length) {
      importedAccount = userDetails.connected_telegrams.find((item: any) => item.import_at)
    }
    if (importedAccount && importedAccount.import_status === 'completed') {
      refetchUserLite({
        ...userDetails,
        is_imported_telegram: true,
      })
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...userDetails,
          is_imported_telegram: true,
        }),
      )
    }
  }, [userDetails])

  useEffect(() => {
    if (!isIntercomOpen) {
      dispatch(SET_INTERCOM_OPEN(false))
      update({ hideDefaultLauncher: true })
    }
  }, [isIntercomOpen])
  useEffect(() => {
    if (
      !learningOpen &&
      articleOpen === 'NONE' &&
      !gettingStartedOpen &&
      !intercomOpen &&
      !articleSeeMoreOpen &&
      !videoSeeMoreOpen
    ) {
      dispatch(SET_FLOAT_VISIBLE(true))
    } else {
      dispatch(SET_FLOAT_VISIBLE(false))
    }
  }, [learningOpen, articleOpen, gettingStartedOpen, intercomOpen, articleSeeMoreOpen, videoSeeMoreOpen])
  useEffect(() => {
    dispatch(SET_FLOAT_VISIBLE(true))
    dispatch(SET_LEARNING_OPEN(false))
    dispatch(SET_ARTICLE_OPEN('NONE'))
    dispatch(SET_GETTING_STARTED_OPEN(false))
  }, [])

  return (
    <ErrorBoundary fallback={<>{'Something went wrong'}</>} onError={handleError}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#7043FF',
          },
        }}>
        <Layout className='hidden sm:flex min-h-screen layout'>
          {showSidebar && <Sidebar />}

          <Layout className='h-screen' style={{ marginLeft: !showSidebar ? 0 : '64px' }}>
            {showSidebar && outOfOutreach && (
              <NotificationStrip
                key={user.id}
                message={`Your campaigns have stopped sending to new contacts because you've reached the send rate limit for this billing cycle. Upgrade now to resume sending.`}
                actionText='Resume Now'
                modalType='upgrade'
                type='warning'
                data={user.username}
              />
            )}
            {showTopBar && <TopBar />}

            <Content className='overflow-auto flex max-h-full'>
              {isSidebarOpen && <SideFilter />}
              {/* CHANGE */}
              {userData?.addons?.unified_inbox === false && window.location.pathname.includes('unifiedInbox') && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#000',
                    fontSize: '20px',
                    zIndex: 1,
                  }}>
                  <p style={{ paddingLeft: '80px' }}>This new feature is only for premium users.</p>
                </div>
              )}

              <Layout
                style={{
                  backgroundColor: '#F9FAFB',
                }}
                className={cx('flex flex-column relative overflow-auto max-h-full')}>
                {/* {window.location.pathname !== '/login' &&
                  window.location.pathname !== '/signup' &&
                  Object.keys(activeFilters).length !== 0 && (
                    <FilterBar
                      emptyMessage={
                        window.location.pathname === '/contacts'
                          ? 'Select contacts to export'
                          : 'Select companies to export contact or data'
                      }
                    />
                  )} */}
                {/* {window.location.pathname.indexOf('outreach') === -1 &&
                  localStorage.getItem('showBanner') === 'true' &&
                  openBanner && (
                    <OutreachBanner
                      handleCloseBanner={() => {
                        setOpenBanner(false)
                        localStorage.setItem('showBanner', 'false')
                      }}
                    />
                  )} */}
                {/* {window.location.pathname !== '/login' &&
                  window.location.pathname !== '/signup' &&
                  window.location.pathname !== '/events' &&
                  window.location.pathname !== '/outreach' &&
                  locationPathName !== 'outreach' &&
                  locationPathName !== 'events' && <ConvrtTabs activeTab={locationPathName} />} */}
                {(locationPathName === 'contacts' || locationPathName === 'companies') && (
                  <ConvrtTabs activeTab={locationPathName} />
                )}
                <div className='flex flex-column h-full'>
                  {userData?.addons?.learning_center && !notForLearning.includes(locationPathName) && (
                    <>
                      <FloatButton
                        shape='square'
                        type='primary'
                        style={{
                          borderRadius: 50,
                          display: !floatVisible ? 'none' : 'block',
                        }}
                        icon={<QuestionOutlined />}
                        className='outreachHelpFloater'
                        onClick={() => {
                          dispatch(SET_LEARNING_OPEN(true))
                        }}
                      />
                      <LearningCenter />
                    </>
                  )}

                  <Routes>
                    {!shouldHideCheckout && (
                      <>
                        <Route path='/checkout' element={<BuyConvrt />} />
                        <Route path='/checkout/success' element={<BuyConvrt />} />
                      </>
                    )}
                    <Route path='/login' element={<Login />} />
                    <Route path='/cs-login' element={<Login isCustomerService={true} />} />
                    <Route path='/signup' element={<SignUpPage />} />
                    <Route path='/signup/success' element={<SignupSuccess />} />
                    <Route path='/reset-password' element={<ResetPasswordPage />} />
                    <Route path='/reset-password/:userId' element={<ResetPasswordPage />} />
                    <Route path='/premium-signup/:registrationCode' element={<SignupRegistrationCode />} />
                    {!navToCheckout ? (
                      <>
                        <Route path='/companies' element={<IntentSignalsPage />} />
                        <Route path='/companies/:selectedId' element={<IntentSignalsPage />} />
                        <Route path='/contacts' element={<ContactsPage />} />
                        <Route path='/contacts/:selectedId' element={<ContactsPage />} />
                        <Route path='/settings' element={<Settings />} />
                        <Route path='/settings/success' element={<Settings />} />
                        <Route path='/settings/salesforce/success' element={<Settings />} />
                        {/* <Route path='/settings' element={<SettingsPage />} />
                    <Route path='/settings/success' element={<SettingsPage />} /> */}
                        {/* <Route path='/events' element={<EventsPage />} />
                    <Route path='/events/:selectedId' element={<EventsPage />} /> */}
                        <Route path='/exposedContacts' element={<ExposedContacts />} />
                        <Route path='/exposedContacts/import-custom-list' element={<ImportCustomListMyContact />} />
                        <Route
                          path='/exposedContacts/import-custom-list/:list_id'
                          element={<ImportCustomListMyContact />}
                        />
                        <Route path='/exposedContacts/:selectedId' element={<ExposedContacts />} />

                        <Route path='/importTelegramContacts' element={<ImportTelegramContacts />} />
                        <Route path='/importTelegramContacts/rawLeads' element={<RawLeads />} />
                        <Route path='/importTelegramContacts/matchedLeads' element={<MatchedLeads />} />
                        <Route path='/importTelegramContacts/matchedLeads/:selectedId' element={<MatchedLeads />} />

                        <Route path='/outreach' element={<Campaigns />} />
                        <Route path='/outreach/create-new-campaign' element={<NewCampaign />} />
                        <Route path='/outreach/edit-campaign' element={<EditCampaign />} />
                        <Route path='/outreach/edit-campaign/:id' element={<EditCampaign />} />
                        <Route path='/company_admin' element={<CompanyAdminPanel />} />
                        {/* <Route path='/checkout/success' element={<ThankYouPage />} /> */}
                        <Route path='*' element={<Navigate to='/companies' />} />
                        <Route path='/unifiedInbox' element={<Chat />} />
                      </>
                    ) : (
                      <Route path='*' element={<Navigate to='/checkout' />} />
                    )}
                  </Routes>
                  {/* {user && userDetails?.did_funnel === false && <TrialFunnel open={true} />} */}
                </div>
              </Layout>
            </Content>
          </Layout>
        </Layout>
        <MobileLayout className='flex sm:hidden' />
      </ConfigProvider>
    </ErrorBoundary>
  )
}

export default App
