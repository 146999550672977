import React, { useEffect, useState } from 'react'
import { Drawer, Button, Input, Upload, Tooltip, message, Form, Spin } from 'antd'
import { InfoCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import styles from './EditAccountDrawer.module.scss'
import type { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload/interface'
import axios from 'axios'
import { post, put } from 'common/api/axios'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useLocalStorage } from 'usehooks-ts'

// Utility function to convert file to base64
const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

// Utility function to check file before upload
const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

interface EditAccountDrawerProps {
  open: boolean
  onClose: () => void
  data: any
  phoneNumber: any
  onSave: () => void
}

const EditAccountDrawer: React.FC<EditAccountDrawerProps> = ({ open, onClose, data, phoneNumber, onSave }) => {
  const [usernameAvailable, setUsernameAvailable] = useState<any>({ available: true, error: '' })
  const [loadingUsernmae, setLoadingUsername] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [accountData, setAccountData] = useState(data)
  const [imageUrlS3, setImageUrlS3] = useState(data.profilePhoto)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [imgError, setImgError] = useState(false)
  const [, setUser] = useLocalStorage('user', null)
  const [getUserDetails] = useLazyGetUserDetailsQuery()

  const checkUsernameAvailability = async (username: string) => {
    setUsernameAvailable({ available: false, error: '' })
    setLoadingUsername(true)
    const response = await post('/user/checkUsernameAvailability', { telegramVal: username })
    setLoadingUsername(false)

    if (response.status === 200 && response?.data?.data?.available === true) {
      return { error: null, available: true }
    } else if (response.status === 200 && response?.data?.data?.available === false) {
      return { error: 'Unavailable Telegram Username', available: false }
    } else {
      return { error: response.data?.error, available: false }
    }
  }

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setAccountData((prevData: any) => ({ ...prevData, [name]: value }))

    if (name === 'username') {
      const isAvailable = await checkUsernameAvailability(value)
      // console.log(isAvailable)
      setUsernameAvailable(isAvailable)
    }
  }

  const handleUploadChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setAccountData((prev: any) => ({ ...prev, profilePhoto: url }))
      })
      setImgError(false)
    }
    if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  const customRequest = async ({ file, onSuccess }: any) => {
    const formData = new FormData()
    formData.append('image', file)
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/upload/uploadImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    setImageUrlS3(data.s3_url)
    onSuccess('ok')
  }

  const handleSave = async () => {
    setLoadingSave(true)
    const { bio, first_name, senderCompany, senderName, username } = form.getFieldsValue()
    const area_code = accountData?.area_code
    const number = accountData?.number

    try {
      await post('/user/changeAccountInfo', {
        area_code: area_code,
        number: number,
        imageUrlS3: imageUrlS3,
        userName: first_name,
        telegramUserName: username,
        bio: bio,
        senderName: senderName,
        senderCompany: senderCompany,
      })
      await put('/user/updateUserName', {
        area_code: area_code,
        number: number,
        userName: username,
        senderName: senderName,
        senderCompany: senderCompany,
      })
      // setCheckLocalStorage((x: any) => !x)
      // updateUserDetails()
      const { data } = await getUserDetails()
      if (data?.id) {
        setUser(data)
      }
      // setIsLoading(false)
      setLoadingSave(false)
      onSave()
      onClose()
    } catch (e: any) {
      console.error(e)
      message.error(e?.response?.data?.error)
      message.error('Something went wrong. Please try again.')
      setLoadingSave(false)
    }
  }

  useEffect(() => {
    const handleFormChange = async () => {
      try {
        const values = await form.validateFields()
        setIsFormValid(
          Object.entries(values).every(([key, field]) => {
            if (key === 'profilePicture') {
              return true
            }
            return field !== undefined && field !== ''
          }) &&
            usernameAvailable.available &&
            imageUrlS3,
        )
      } catch {
        setIsFormValid(false)
      }
    }

    // form.setFieldsValue(accountData);
    handleFormChange()
    // form.onFieldsChange(handleFormChange);
  }, [form, accountData, usernameAvailable])

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  )

  return (
    <Drawer
      className='editAccountDrawer'
      closable={false}
      title={
        <div className={styles.header}>
          <span>Telegram Accounts</span>
          <p>Edit {data?.phoneNumber}</p>
        </div>
      }
      placement='right'
      onClose={onClose}
      open={open}
      width={500}
      extra={
        <Button type='primary' onClick={handleSave} loading={loadingSave} disabled={!isFormValid}>
          Save
        </Button>
      }>
      <Form form={form} layout='vertical'>
        <div className={styles.form}>
          <div className={styles.field}>
            <div className={styles.label}>
              Profile Photo{' '}
              <Tooltip title='Upload a profile photo'>
                <InfoCircleOutlined style={{ marginLeft: '6px', color: 'gray' }} />
              </Tooltip>
            </div>
            <div className={styles.upload}>
              <Form.Item name='profilePicture' style={{ marginBottom: '0px' }}>
                <Upload
                  name='profilePhoto'
                  listType='picture-circle'
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleUploadChange}
                  customRequest={customRequest}>
                  {imgError || !accountData?.profilePhoto ? (
                    // <ImageWithFallback
                    //   style={{ width: '100%' }}
                    //   src={accountData?.profilePhoto}
                    //   fallbackStrOrElement={uploadButton}
                    // />
                    uploadButton
                  ) : (
                    <img
                      src={accountData?.profilePhoto || ''}
                      alt='Profile'
                      style={{ width: '100%' }}
                      onError={() => {
                        setImgError(true)
                      }}
                    />
                  )}
                </Upload>
              </Form.Item>
            </div>
          </div>
          <Form.Item
            style={{ fontWeight: 600 }}
            label='Full name'
            name='first_name'
            initialValue={accountData?.first_name || accountData?.name}
            rules={[{ required: true, message: 'Please enter the full name' }]}>
            <Input name='first_name' onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            style={{ fontWeight: 600 }}
            label={
              <div>
                <span>Username</span>
                {loadingUsernmae && (
                  <Spin style={{ marginLeft: '5px' }} indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />
                )}
              </div>
            }
            name='username'
            initialValue={accountData?.username || accountData?.handle?.replace('@', '')}
            validateStatus={usernameAvailable.available ? 'success' : 'error'}
            help={usernameAvailable.error}
            rules={[{ required: true, message: 'Please enter the username' }]}>
            <Input name='username' onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            style={{ fontWeight: 600 }}
            label='Bio'
            name='bio'
            initialValue={accountData?.bio}
            rules={[{ required: true, message: 'Please enter a bio' }]}>
            <Input.TextArea rows={8} name='bio' maxLength={70} showCount onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            style={{ fontWeight: 600 }}
            label='Sender name'
            name='senderName'
            initialValue={accountData?.name}
            rules={[{ required: true, message: 'Please enter the sender name' }]}>
            <Input name='senderName' onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            style={{ fontWeight: 600 }}
            label='Sender Company'
            name='senderCompany'
            initialValue={accountData?.company}
            rules={[{ required: true, message: 'Please enter the company name' }]}>
            <Input name='senderCompany' onChange={handleInputChange} />
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  )
}

export default EditAccountDrawer
