import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Input, Modal, TablePaginationConfig, message, Alert, Space } from 'antd'
import _ from 'lodash'
import ghost from 'common/assets/svg/ghost.svg'

import address_book from 'common/assets/svg/address_book.svg'
//import gray_lock from 'common/assets/svg/gray_lock.svg'
import FancyDrawer from 'common/components/fancy-drawer/fancy-drawer'
import FancyTable from 'common/components/fancy-table/fancy-table'

import CompanyDetails from 'features/intent-signals/components/company-details/company-details'
import { IntentSignalsTableColumns } from 'features/intent-signals/components/intent-signals-table-columns/intent-signals-table-columns'

import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import { useAppDispatch, useAppSelector } from 'state'
// import IntentSignalsPermanentPaywall from './components/paywalls/IntentSignalsPermanentPaywall'
import {
  // useGetCompaniesCSVMutation,
  // useGetCompaniesHubSpotMutation,
  useLazyGetCompaniesQuery,
  useGetCompanyMutation,
  useLazyAddNewListQuery,
  useLazyGetCompaniesTelegramContactsQuery,
  useLazyGetListsQuery,
  useLazyUpdateListQuery,
} from './state/api/CompaniesApi'
import { useLazyGetLoginHubSpotQuery } from '../settings/state/api/SettingsApi'

import classnames from 'classnames'
import { FilterBar } from 'common/components/FilterBar/FilterBar'
import { useActiveFilters } from 'common/hooks/useFilters'
// import { addUserGuideScript } from 'common/utils/userGuideScript'
// import FileSaver from 'file-saver'
// import { BsDownload, BsChevronDown } from 'react-icons/bs'
import { setSidebarOpen, setTechSidebarOpen } from 'state/filtersSlice/filtersSlice'
import {
  SET_FILTER,
  SET_LISTS,
  SET_SELECTED_COMPANIES,
  SET_SELECTED_COMPANIES_DETAILS,
  SET_LAST_ACTION_PAGE,
  SET_IS_FILTER_CHANGED,
  SET_COMPANY_SELECTED_ROWS,
  SET_DELETE_COMPANY_SELECTED_ROWS,
  SET_GLOBAL_COMPANY_SELECTED,
} from './state/slice/companiesSlice'
import { SET_HUBSPOT_DEALS } from '../contacts/state/slice/contactsSlice'
import { FancyImage } from '../contacts/components/FancyImage/FancyImage'
// import { ConfirmBox } from '../../common/constants/modal.constants'
import { SET_EVENTS_FILTERS } from '../events/state/slice/events.slice'
import { injectionRegex } from '../../common/static-data/userData'
import { MESSAGE_ERROR } from '../../common/constants/messages.constants'
import { useGetForContactsListMutation } from '../contacts/state/api/ContactsApi'
import { SaveFilledModal } from './save-filters-modal'
import { useConnectPlus } from 'common/providers/ConnectPlusProvider'
import { useShowStartScreen } from 'common/providers/ShowStartScreenProvider'
import { useAuth } from 'common/hooks/useAuth.hooks'
import StartScreen from 'features/StartScreen/StartScreen'

import styles from './intent-signals.module.scss'
import OutreachActions from 'features/Outreach/components/OutreachActions'
import ErrorMessage from './components/ErrorMessage'
import { get } from 'common/api/axios'
import OutreachBanner from 'common/components/TrialBanner/OutreachBanner'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'
import AreYouSureModal from 'common/components/General/AreYouSureModal'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
//import { ControlOutlined } from '@ant-design/icons'

const IntentSignalsPage: React.FC = () => {
  const [refetchLoginHubSpot] = useLazyGetLoginHubSpotQuery()
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [outOfCredits, setOutOfCredits] = useState(false)

  // eslint-disable-next-line
  const [tokenHubSpot] = useState(localStorage.getItem('hubspot_access_token'))
  const [inThisPage, setInThisPage] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const { userDetails, isBetaOrTrialUser, isPaidTrialUser } = useAuth()
  const [userData, setUserData] = useState(userDetails)
  const navigate = useNavigate()
  const { selectedId } = useParams()
  const { search, pathname } = useLocation()
  const [openBanner, setOpenBanner] = useState(true)

  const { showStartScreen, setShowStartScreen } = useShowStartScreen()
  //setShowStartScreen(false)
  const [, setCookie] = useCookies(['selected_companies'])

  const [showDetails, setShowDetails] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const { filter, company_selected_rows, delete_company_selected_rows } = useAppSelector((state) => state.companies)
  const { last_action_page, is_filter_changed } = useAppSelector((state) => state.companies)
  const { selected_companies, company_lists } = useAppSelector((state) => state.companies)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [companyDetails, setCompanyDetails] = useState<any>(null)
  const [selectedList, setSelectedList] = useState<string>('')
  const [tableParams, setTableParams] = useState<TablePaginationConfig>({ current: last_action_page, pageSize: 10 })

  const [isErrorModalOpen, setIsModalErrorModalOpen] = useState(false)
  const [addToListModal, setAddToListModal] = useState(false)
  const [addToListInput, setAddToListInput] = useState(false)

  const { company } = useAppSelector((state) => state.companies)
  const { save_filter_properties } = useAppSelector((state) => state.companies)
  const [refetchAddList] = useLazyAddNewListQuery()
  const [refetchLists] = useLazyGetListsQuery()
  const [refetchUpdateList] = useLazyUpdateListQuery()
  const [refetchGetCompaniesTelegramContactsQuery] = useLazyGetCompaniesTelegramContactsQuery()
  const [allCompanies, setAllCompanies] = useState<any>([])
  const [chosenCompanies, setChosenCompanies] = useState<any>([])

  const [activeFilters, setActiveFilters] = useActiveFilters()
  const { setConnectPlusSum, setConnectPlusPictures } = useConnectPlus()
  const [openOutOfCreditsModal, setOpenOutOfCreditsModal] = useState(false)
  const [openLoadingErrorModal, setOpenLoadingErrorModal] = useState(false)

  const dispatch = useAppDispatch()

  const [refetchGetCompany, { data: details, isLoading: isLoadingCompany, error: selectedCompanyError }] =
    useGetCompanyMutation()
  const [refetchGetCompanies, { data: companies, isFetching, error: companyErrors }] = useLazyGetCompaniesQuery({
    fixedCacheKey: 'companies-filters',
  })
  // const [fetchCSVExport] = useGetCompaniesCSVMutation()
  //const [fetchHubSpot] = useGetCompaniesHubSpotMutation()
  const [refetchGetForContactsList] = useGetForContactsListMutation()

  const handleCloseBanner = () => {
    localStorage.setItem('showBanner', 'false')
    setOpenBanner(false)
  }

  useEffect(() => {
    if (localStorage.getItem('fetchDeals') === 'true') {
      const fetchHubspotDealData = async () => {
        const fetchedDeals = await get('/outreach/getHubspotAllDeals')
        dispatch(SET_HUBSPOT_DEALS(fetchedDeals.data.response))
      }
      const tokenHubSpot = localStorage.getItem('hubspot_access_token')
      if (tokenHubSpot && tokenHubSpot !== 'null') {
        window.onload = () => {
          fetchHubspotDealData().catch(console.error)
          localStorage.setItem('fetchDeals', 'false')
        }
      }
    }
  }, [])

  useEffect(() => {
    if (companies) {
      setAllCompanies((prev: any) => {
        const allCompanyIds = new Set(prev.map((company: any) => company.id))
        const newCompanies = companies.rows.filter((company: any) => !allCompanyIds.has(company.id))
        return [...prev, ...newCompanies]
      })
    }
  }, [companies])

  useEffect(() => {
    //setSelectedRows(selected_companies)
    const comp = allCompanies?.filter((company: any) => selected_companies.includes(company?.id))
    setChosenCompanies(comp)
  }, [selected_companies])

  useEffect(() => {
    if (company) {
      setCompanyDetails(company)
    } else if (!company && details) {
      setCompanyDetails(details)
    }
  }, [company, details])

  useEffect(() => {
    if (companies?.totalNumber) {
      setTableParams({ ...tableParams, total: Number(companies?.totalNumber) })
    }

    const getTelegram = async () => {
      const { data } = await refetchGetCompaniesTelegramContactsQuery()

      setConnectPlusSum(data?.totalContacts)
      setConnectPlusPictures(data?.pictures)
    }

    getTelegram()
  }, [companies])

  const companiesRows = useMemo(
    () => (Object.keys(activeFilters).length === 0 ? [] : companies?.rows),
    [companies, filter],
  )

  const refetchCompanies = () => {
    const activeFiltersData = { ...activeFilters }

    if (!Object.keys(activeFiltersData)?.length) return

    if ('searchBarCompany' in activeFiltersData) {
      activeFiltersData.searchBarCompany = activeFiltersData.searchBarCompany.filter((item: any) => item !== null)
    }
    refetchGetCompanies({
      current: last_action_page,
      pageSize: tableParams.pageSize,
      body: _.omitBy(activeFiltersData, _.isNil),
    })
  }

  useEffect(() => {
    dispatch(SET_LAST_ACTION_PAGE(tableParams.current))
  }, [tableParams.current])

  useEffect(() => {
    refetchCompanies()
  }, [last_action_page, tableParams.pageSize, activeFilters])

  useEffect(() => {
    if (is_filter_changed && Object.values(filter).length === 0) {
      setSelectedRows([])
      dispatch(SET_SELECTED_COMPANIES([]))
      dispatch(SET_COMPANY_SELECTED_ROWS([]))
      dispatch(SET_GLOBAL_COMPANY_SELECTED([]))
      setTableParams({ current: 1, pageSize: 10 })
      dispatch(SET_IS_FILTER_CHANGED(false))
    }

    dispatch(SET_FILTER(activeFilters))
    setShowStartScreen(!Object.keys(activeFilters)?.length)
  }, [activeFilters])

  useEffect(() => {
    if (selectedCompanyError?.status !== 470) return

    setShowDetails(false)
    setIsModalErrorModalOpen(true)
  }, [selectedCompanyError])

  useEffect(() => {
    if (filter && Object.values(filter).length !== 0) {
      let tempFilter = { ...filter }
      if (filter?.contactsList) {
        tempFilter.companyList = tempFilter.contactsList
        delete tempFilter?.contactsList
      }
      if (filter?.contactsCountry) {
        // tempFilter.country = tempFilter.contactsCountry
        delete tempFilter.contactsCountry
      }
      if (filter.departments) {
        delete tempFilter?.departments
      }
      if (filter.jobTitle) {
        delete tempFilter?.jobTitle
      }
      if (filter.managementLevel) {
        delete tempFilter?.managementLevel
      }
      if (filter.companiesName) {
        delete tempFilter?.companiesName
      }
      if (filter.searchBarContact) {
        delete tempFilter?.searchBarContact
      }
      if (filter.circle) {
        delete tempFilter?.circle
      }
      if (filter?.contactsIncludeList) {
        delete tempFilter.contactsIncludeList
      }
      if (filter?.contactsExcludeList) {
        delete tempFilter.contactsExcludeList
      }
      if (filter?.excludeExposedContacts) {
        delete tempFilter.excludeExposedContacts
      }
      if (filter?.excludeOutreachedContacts) {
        delete tempFilter.excludeOutreachedContacts
      }
      setActiveFilters(tempFilter)
      dispatch(SET_FILTER(tempFilter))
      if (Object.values(tempFilter).length !== 0) dispatch(SET_IS_FILTER_CHANGED(true))
    }
    dispatch(SET_EVENTS_FILTERS({}))
    //dispatch(SET_SELECTED_CONTACTS([]))
    dispatch(setSidebarOpen(true))
    dispatch(SET_SELECTED_COMPANIES_DETAILS([]))
    let expires = new Date()
    expires.setTime(expires.getTime() * 1000)
    setCookie('selected_companies', '', { path: '/', expires })
    // if (userDetails?.did_funnel) addUserGuideScript()
    setInThisPage(true)
    return () => {
      dispatch(setSidebarOpen(false))
      dispatch(setTechSidebarOpen(false))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setShowDetails(true)
      await refetchGetCompany(selectedId)
    }
    if (selectedId) {
      fetchData()
    }
  }, [selectedId])

  useEffect(() => {
    if (inThisPage && delete_company_selected_rows) {
      setSelectedRows([])
    }
  }, [delete_company_selected_rows])

  useEffect(() => {
    if (delete_company_selected_rows) {
      setSelectedRows([])
      dispatch(SET_SELECTED_COMPANIES([]))
      dispatch(SET_DELETE_COMPANY_SELECTED_ROWS(false))
    } else {
      selectedRows.forEach((item: string) => {
        let selectedRowsList = selected_companies
        if (!selectedRowsList.includes(item)) {
          dispatch(SET_SELECTED_COMPANIES([...selected_companies, item]))
        }
      })
    }
    //dispatch(SET_DELETE_COMPANY_SELECTED_ROWS(false))
  }, [selectedRows, selected_companies])

  // useEffect(() => {
  //   if (is_filter_changed) {
  //     setSelectedRows([])
  //   }
  // }, [filter, activeFilters, is_filter_changed])

  // if (isLoading) return <LoadingBox />

  useEffect(() => {
    if (companyErrors) {
      // console.log(companyErrors)
      if (companyErrors.status === 504 || companyErrors.status === 'FETCH_ERROR') {
        setOpenLoadingErrorModal(true)
      } else {
        setOpenOutOfCreditsModal(true)
      }
    }
  }, [companyErrors])

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setUserData(data)
    }
    getUserDetails()
  }, [])

  // if (companyErrors) {
  //   setOpenOutOfCreditsModal(true)
  // if (companyErrors?.status === 470 && userDetails.subscription_type !== 'PREMIUM') {
  //   return (
  //     <div className='flex justify-content-center align-items-center py-3'>
  //       <ErrorMessage />
  //       {/* <IntentSignalsPermanentPaywall /> */}
  //     </div>
  //   )
  // } else if (companyErrors?.status === 500 || userDetails.subscription_type === 'PREMIUM') {
  //   return (
  //     <div className='flex justify-content-center align-items-center'>
  //       <ErrorMessage />
  //     </div>
  //   )
  // } else if (companyErrors?.error.indexOf('NetworkError when attempting to fetch resource.') >= 0) {
  //   return <LoadingBox />
  // }
  // return (
  //   <div className='flex justify-content-center align-items-center'>
  //     {/* <IntentSignalsPermanentPaywall trialExpired={true} /> */}
  //     <ErrorMessage />
  //   </div>
  // )
  // }

  const handleSelectedRows = (rows: any, keys: any) => {
    dispatch(SET_COMPANY_SELECTED_ROWS(keys))
    const setCompanySelected: any = new Set([...selected_companies, ...keys])

    dispatch(SET_GLOBAL_COMPANY_SELECTED([...setCompanySelected]))
    setSelectedRows(keys)

    //dispatch(SET_SELECTED_COMPANIES([...selected_companies, ...keys]))
  }

  const handleUnSelectRows = (rows: any) => {
    let arrSelectedCompanies = selected_companies
    for (let i = 0; i < rows.length; i++) {
      arrSelectedCompanies = arrSelectedCompanies.filter((item) => item !== rows[i]?.id)
    }
    dispatch(SET_SELECTED_COMPANIES(arrSelectedCompanies))
  }

  const handleRemoveRow = (key: string) => {
    let arr = selected_companies.filter((e) => e !== key)
    dispatch(SET_SELECTED_COMPANIES(arr))
  }

  const handleAddNewItem = async (event: any) => {
    if (13 === event.keyCode) {
      if (injectionRegex.test(event.target.value)) {
        message.error(MESSAGE_ERROR.INVALID_LIST_NAME)
      } else {
        let checkItem = company_lists.find((item: any) => {
          return item.name === event.target.value
        })
        if (!checkItem) {
          let result = await refetchAddList({ name: event.target.value, type: 1 })
          if (result?.error?.status === 470) {
            setOutOfCredits(true)
          } else {
            if (result) {
              let getLists = await refetchLists()
              if (getLists) dispatch(SET_LISTS(getLists.data))
              message.success(`The list, '${event.target.value}' was created successfully`)
              setAddToListInput(!addToListInput)
            }
          }
        } else {
          message.success(`We already have a list with the same name!`)
        }
      }
    }
  }

  const handleAddItemsToList = async () => {
    const list = company_lists?.filter((item: any) => item.id === selectedList)

    if (list.length > 0) {
      let com_assignedItems = list[0].company_ids ? list[0].company_ids : ''
      selected_companies.forEach((item) => {
        if (com_assignedItems.indexOf(item) === -1) com_assignedItems += item + ','
      })
      await refetchUpdateList({
        id: selectedList,
        items_list: com_assignedItems,
        field_name: 'company_ids',
        page_type: 'company',
      })

      let ids = ''
      selected_companies?.forEach((item: any) => {
        if (item && ids.indexOf(item) === -1) {
          ids += item + ','
        }
      })

      const result = await refetchGetForContactsList({
        company_ids: ids,
      })

      let assignedItems = list[0].contact_ids ? list[0].contact_ids : ''

      for (let index = 0; index < result?.data?.rows?.length; index++) {
        if (assignedItems.indexOf(result?.data?.rows[index]?.index) === -1)
          assignedItems += result?.data?.rows[index]?.index + ','
      }

      await refetchUpdateList({ id: selectedList, items_list: assignedItems, field_name: 'contact_ids' })
    }

    let getLists = await refetchLists()
    if (getLists) dispatch(SET_LISTS(getLists.data))
    message.success(`Items added to the list successfully`)
    setAddToListModal(false)
    dispatch(SET_COMPANY_SELECTED_ROWS([]))
    dispatch(SET_GLOBAL_COMPANY_SELECTED([]))

    setSelectedRows([])
  }

  const handleLoginHubSpot = async () => {
    const result = await refetchLoginHubSpot()

    const newWindow = window.open(result.data.response, '_blank')

    if (newWindow) {
      newWindow.focus()
    }
  }

  const handleSelectFifty = () => {
    const newCompanyIds = [...selected_companies, ...companies.company_ids]
    const uniqueCompanyIds = Array.from(new Set(newCompanyIds))
    dispatch(SET_SELECTED_COMPANIES(uniqueCompanyIds))
    dispatch(SET_COMPANY_SELECTED_ROWS(uniqueCompanyIds))
    dispatch(SET_GLOBAL_COMPANY_SELECTED(uniqueCompanyIds))
  }

  const handleSelectAllData = () => {
    if (userData.addons.allow_select_all_data) {
      const newCompanyIds = [...selected_companies, ...companies.allCompanyIdsData]
      const uniqueCompanyIds = Array.from(new Set(newCompanyIds))
      dispatch(SET_SELECTED_COMPANIES(uniqueCompanyIds))
      dispatch(SET_COMPANY_SELECTED_ROWS(uniqueCompanyIds))
      dispatch(SET_GLOBAL_COMPANY_SELECTED(uniqueCompanyIds))
    } else {
      message.warning('Your plan does not support selecting all data.')
    }
  }

  return (
    <>
      {window.location.pathname.indexOf('outreach') === -1 &&
        openBanner &&
        localStorage.getItem('showBanner') === 'true' &&
        (isBetaOrTrialUser || isPaidTrialUser) && <OutreachBanner handleCloseBanner={handleCloseBanner} />}
      {!showStartScreen && (
        <FilterBar
          showSort={false}
          emptyMessage={showStartScreen ? '' : 'Select companies to export contact or data'}
        />
      )}

      {/* {!!userDetails?.did_funnel && showStartScreen && (!is_filter_changed || Object.values(filter).length === 0) ? ( */}
      {showStartScreen && (!is_filter_changed || Object.values(filter).length === 0) ? (
        <>
          <StartScreen />

          <FancyDrawer
            onClose={() => {
              setShowDetails(false)
              navigate(-1)
            }}
            open={showDetails}
            customClass={'companyDetails'}
            title={
              !isLoadingCompany && (
                <div className={'drawerTitle'}>
                  <FancyImage url={companyDetails?.s3_logo} iscompany={true} name={companyDetails?.name} />
                  <p>
                    {companyDetails?.name}
                    <a href={companyDetails?.url} target='_blanck'>
                      {companyDetails?.url}
                    </a>
                  </p>
                </div>
              )
            }>
            {!isLoadingCompany ? (
              <CompanyDetails
                data={companyDetails}
                toggleHide={(value: any) => {
                  setShowDetails(value)
                }}
              />
            ) : (
              <LoadingBox />
            )}
          </FancyDrawer>
        </>
      ) : (
        <>
          <div className='flex flex-column flex-1'>
            {companiesRows?.length === 0 ? (
              <div className='emptyContacts' style={{ marginTop: '10%' }}>
                <img src={ghost} alt={''} />
              </div>
            ) : (
              <div className='flex-1'>
                <FancyTable
                  loading={isFetching}
                  page_type={'company'}
                  displayAll={companies?.displayAll}
                  totalItems={companies?.totalNumber}
                  columns={IntentSignalsTableColumns}
                  totalDataNumber={companies?.totalDataNumber}
                  allIdsData={companies?.allCompanyIdsData}
                  data={isFetching ? [] : companiesRows}
                  handleShowDetails={async (id) => {
                    navigate(`${pathname}/${id}${search}`)
                  }}
                  key={'id'}
                  tableParams={{ ...tableParams, current: last_action_page }}
                  setTableParams={setTableParams}
                  handleSelectedRows={handleSelectedRows}
                  handleUnSelectRows={handleUnSelectRows}
                  selected_items={selected_companies}
                  handleRemoveRow={handleRemoveRow}
                  hasBanner={openBanner}
                  handleSelectFifty={handleSelectFifty}
                  handleSelectAllData={handleSelectAllData}
                />
              </div>
            )}
            <Modal
              centered
              open={isErrorModalOpen}
              onCancel={() => setIsModalErrorModalOpen(false)}
              okButtonProps={{
                className: 'hidden',
              }}
              cancelButtonProps={{
                className: 'hidden',
              }}>
              <div className='flex justify-content-center'>
                <ErrorMessage
                  caption={`Wow, you're really crushing it!`}
                  subCaption={`Our system noticed you've hit the limit of your plan. Please contact our sales team.`}
                  buttonText='Contact Support'
                  onClick={() => window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')}
                />
                {/* {userDetails.subscription_type === 'PREMIUM' ? <ErrorMessage /> : <IntentSignalsPermanentPaywall />} */}
              </div>
            </Modal>
            <FancyDrawer
              onClose={() => {
                setShowDetails(false)
                navigate(-1)
              }}
              open={showDetails}
              customClass={'companyDetails'}
              title={
                !isLoadingCompany && (
                  <div className={'drawerTitle'}>
                    <FancyImage url={companyDetails?.s3_logo} iscompany={true} name={companyDetails?.name} />
                    <p>
                      {companyDetails?.name}
                      <a href={companyDetails?.url} target='_blanck'>
                        {companyDetails?.url}
                      </a>
                    </p>
                  </div>
                )
              }>
              {!isLoadingCompany ? (
                <CompanyDetails
                  data={companyDetails}
                  toggleHide={(value: any) => {
                    setShowDetails(value)
                  }}
                />
              ) : (
                <LoadingBox />
              )}
            </FancyDrawer>
            <div className={classnames(styles.companiesFooter)}>
              <OutreachActions
                isCompany={true}
                refetchData={refetchCompanies}
                selectedCompaniesObject={chosenCompanies}
              />
              <span className={styles.verticalLine} />
              <Button
                type='primary'
                className='flex align-items-center gap-2'
                disabled={selected_companies.length === 0}
                onClick={() => {
                  if (company_selected_rows.length > 0) {
                    // dispatch(SET_SELECTED_COMPANIES_DETAILS([]))
                    dispatch(SET_GLOBAL_COMPANY_SELECTED(selected_companies))
                    dispatch(SET_SELECTED_COMPANIES_DETAILS([]))

                    let expires = new Date()
                    expires.setTime(expires.getTime() * 1000)
                    setCookie('selected_companies', company_selected_rows, { path: '/', expires })

                    navigate(`/contacts`)
                  }
                }}>
                <img src={address_book} alt={''} />
                <span>Show Contacts</span>
              </Button>
            </div>
          </div>

          <Modal
            className='modalErrorMessage'
            title='Choose export destination'
            open={showErrorMessage}
            onCancel={() => {
              setShowErrorMessage(false)
              setErrorMessage('')
            }}
            footer={null}>
            <p>{errorMessage}</p>
          </Modal>
          <Modal
            title='Add to List:'
            className='listModal'
            centered
            open={addToListModal}
            footer={
              <Button
                className='align-items-center gap-2 addToList'
                onClick={handleAddItemsToList}
                disabled={!selectedList}>
                Add to List
              </Button>
            }
            onCancel={() => setAddToListModal(false)}>
            <>
              {company_lists?.map((item: any, index: any) => (
                <div className='rowListItem' key={index}>
                  <span
                    className={classnames('listitem', selectedList === item.id ? 'selectedList' : '')}
                    onClick={() => {
                      setSelectedList(item.id)
                    }}>
                    {item.name}
                  </span>
                </div>
              ))}
              {addToListInput && (
                <div className='addNewContainer'>
                  <Input placeholder='' className='addNewInput' onKeyDown={handleAddNewItem} />
                </div>
              )}
              <span
                className='createANewItem'
                onClick={() => {
                  setAddToListInput(!addToListInput)
                }}>
                + Create New
              </span>
            </>
          </Modal>
          {save_filter_properties.open_modal && <SaveFilledModal />}

          {openOutOfCreditsModal && (
            <Modal
              centered
              open={openOutOfCreditsModal}
              onCancel={() => {
                setOpenOutOfCreditsModal(false)
                navigate('/companies')
              }}
              okButtonProps={{
                className: 'hidden',
              }}
              cancelButtonProps={{
                className: 'hidden',
              }}>
              <div className='flex justify-content-center'>
                <ErrorMessage
                  caption={`Wow, you're really crushing it!`}
                  subCaption={`Our system noticed you've hit the limit of plan. Please contact our sales team.`}
                  buttonText='Contact Support'
                  onClick={() => window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')}
                />
              </div>
            </Modal>
          )}

          {showAlert && (
            <Alert
              style={{ position: 'absolute', bottom: '69px', right: ' 20px' }}
              message='Enrich HubSpot with Convrt'
              description='To enrich contacts/companies and create/update deals, connecting your HubSpot account with Convrt is required'
              type='info'
              action={
                <Space direction='vertical'>
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => {
                      setShowAlert(false)
                      handleLoginHubSpot()
                    }}>
                    Connect
                  </Button>
                </Space>
              }
              closable
              onClose={() => setShowAlert(false)}
            />
          )}
          {outOfCredits && (
            <OutOfCreditsModal
              open={outOfCredits}
              onClose={() => {
                setOutOfCredits(false)
              }}
              creditType={'list'}
              location='lists'
            />
          )}
          {openLoadingErrorModal && (
            <AreYouSureModal
              open={openLoadingErrorModal}
              onClose={() => {
                setOpenLoadingErrorModal(false)
              }}
              title='Oops! It seems like this is taking longer than expected.'
              message="Please try refreshing the page or consider adjusting your filters for a smoother experience. If the issue persists, we're here to help!"
              onConfirm={() => {
                window.location.reload()
              }}
              widthStyle={600}
              confirmText='Refresh'
            />
          )}
        </>
      )}
    </>
  )
}

export default IntentSignalsPage
