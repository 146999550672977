import React, { useEffect, useState } from 'react'
import { Tooltip, Tag, Dropdown, MenuProps, Input, Spin } from 'antd'
import { CopyOutlined, EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import styles from './SocialAccountTableColumns.module.scss'
import convrt from 'common/assets/svg/convrt_profile_pic.svg'
import ImageWithFallback from 'common/components/General/ImageWithFallback'

const getColumns = (
  platform: string,
  setOpenDrawer: any,
  setPhoneNumber: any,
  onEditAccount: (e: any) => void,
  setOpenQrcodPage: any,
  handleOutreachEdit: any,
  isLoadingAccount?: boolean,
) => {
  const items: MenuProps['items'] = [
    {
      key: 'edit',

      // label: 'Edit Account Details'
      label: (
        <div>
          <span>Edit Account Details</span>
          {isLoadingAccount && (
            <Spin
              style={{ marginLeft: '6px' }}
              indicator={<LoadingOutlined style={{ fontSize: 16, color: 'black' }} spin />}
            />
          )}
        </div>
      ),
    },
    { key: 'getCode', label: 'Get Login Code' },
  ]

  const handleCopy = (url: string) => {
    try {
      const tempInput = document.createElement('input')
      tempInput.value = url
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
    } catch (error) {
      console.error('Unable to copy link to clipboard', error)
    }
  }

  switch (platform) {
    case 'telegram':
      return [
        {
          title: 'Profile Photo',
          dataIndex: 'profilePhoto',
          key: 'profilePhoto',
          width: 120,
          render: (text: string, data: any) => {
            return (
              <ImageWithFallback
                src={text}
                fallbackStrOrElement={convrt}
                style={{ width: 32, height: 32, borderRadius: '50%', marginLeft: '25px' }}
              />
            )
          },
        },
        {
          title: 'Phone Number',
          dataIndex: 'number',
          key: 'number',
          width: 180,
          render: (text: string, data: any) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {`${data?.area_code}${text}`}
              <Tooltip title='Copy to clipboard'>
                <CopyOutlined
                  style={{ color: '#7043ff', cursor: 'pointer', marginLeft: '5px' }}
                  onClick={() => {
                    handleCopy(text)
                  }}
                />
              </Tooltip>
            </div>
          ),
        },
        {
          title: 'Username',
          dataIndex: 'handle',
          key: 'handle',
          width: 175,
        },
        {
          title: 'Name (@sender_full_name)',
          dataIndex: 'name',
          key: 'name',
          width: 150,
          render: (name: any, data: any) => {
            if (data.type === 'Connect+') return <span>{``}</span>
            return (
              <Input
                key={data.key}
                defaultValue={name}
                onBlur={(e: any) => {
                  handleOutreachEdit(data.id, e, 'sender_full_name')
                }}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  backgroundColor: '#e3e3e366',
                }}
                onFocus={(e: any) => (e.target.style.backgroundColor = 'whitesmoke')}
              />
            )
          },
        },
        {
          title: 'Company (@sender_company)',
          dataIndex: 'company',
          key: 'company',
          width: 150,
          render: (company: any, data: any) => {
            if (data.type === 'Connect+') return <span>{``}</span>

            return (
              <Input
                defaultValue={company}
                onBlur={(e: any) => {
                  handleOutreachEdit(data.id, e, 'sender_company')
                }}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  backgroundColor: '#e3e3e366',
                }}
                onFocus={(e: any) => (e.target.style.backgroundColor = 'whitesmoke')}
              />
            )
            // return <span>{name}</span>
          },
        },
        {
          title: 'Account Type',
          dataIndex: 'type',
          key: 'type',
          width: 120,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          render: (status: string, data: any) => {
            return (
              <Tag className={status === 'Connected' ? styles.statusConnected : styles.statusDisconnected}>
                {status}
              </Tag>
            )
          },
        },
        {
          title: 'Campaigns',
          dataIndex: 'campaigns',
          key: 'campaigns',
          render: (campaigns: any) => {
            const firstThreeCampaigns = campaigns?.slice(0, 1)
            const additionalCampaigns = campaigns?.slice(1)
            return (
              <>
                {firstThreeCampaigns?.map((tag: any) =>
                  tag?.length > 12 ? (
                    <Tooltip title={tag}>
                      <Tag className={styles.tag}>{tag?.slice(0, 12)?.trim() + '...'}</Tag>
                    </Tooltip>
                  ) : (
                    <Tag className={styles.tag}>{tag}</Tag>
                  ),
                )}
                {additionalCampaigns?.length > 0 && (
                  <Tooltip
                    overlayStyle={{ maxWidth: '600px', backgroundColor: 'white' }}
                    overlayInnerStyle={{ backgroundColor: 'white' }}
                    title={additionalCampaigns?.map((tag: any) => (
                      <Tag className={styles.tag}>{tag}</Tag>
                    ))}>
                    <Tag className={styles.tag}>+{additionalCampaigns?.length}</Tag>
                  </Tooltip>
                )}
              </>
            )
          },
        },
        {
          title: '',
          key: 'actions',
          width: 50,
          render: (record: any, data: any) => (
            <CustomDropdown
              data={data}
              isLoadingAccount={isLoadingAccount}
              onEditAccount={onEditAccount}
              setPhoneNumber={setPhoneNumber}
              setOpenQrcodPage={setOpenQrcodPage}
            />
            // <Dropdown
            //   menu={{
            //     items,
            //     onClick: async (e: any) => {
            //       switch (e?.key) {
            //         case 'edit':
            //           try {
            //             // const telegram_number = `${data.area_code}${data.number}`
            //             // const accountData = await getTGInfo(telegram_number)
            //             // setPhoneNumber({ area_code: data.area_code, number: data.number })
            //             onEditAccount(data)
            //             // setOpenDrawer(true)
            //             break
            //           } catch (e) {
            //             console.error('ERROR: ', e)
            //             break
            //           }

            //         case 'getCode':
            //           try {
            //             setPhoneNumber({ area_code: data.area_code, number: data.number })
            //             setOpenQrcodPage(data)
            //             break
            //           } catch (e) {
            //             console.error('ERROR: ', e)
            //             break
            //           }
            //       }
            //     },
            //   }}>
            //   <EllipsisOutlined className={styles.moreOptions} style={{ fontSize: '22px', color: '#5E6C84' }} />
            // </Dropdown>
          ),
        },
      ]
    case 'instagram':
      return [
        {
          title: 'Profile Photo',
          dataIndex: 'profilePhoto',
          key: 'profilePhoto',
          render: (text: string) => (
            <img src={text} alt='profile' style={{ width: 32, height: 32, borderRadius: '50%' }} />
          ),
        },
        {
          title: 'User name',
          dataIndex: 'userName',
          key: 'userName',
        },
        {
          title: 'Full name',
          dataIndex: 'fullName',
          key: 'fullName',
        },
        {
          title: 'Bio',
          dataIndex: 'bio',
          key: 'bio',
          render: (text: string) => (
            <Tooltip title={text.length > 20 ? text : ''}>
              <span>{text.length > 20 ? `${text.substring(0, 20)}...` : text}</span>
            </Tooltip>
          ),
        },
        {
          title: 'Outreach method',
          dataIndex: 'outreachMethod',
          key: 'outreachMethod',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status: string) => (
            <Tag className={status === 'Connected' ? styles.statusConnected : styles.statusDisconnected}>{status}</Tag>
          ),
        },
        {
          title: 'Photos',
          dataIndex: 'photos',
          key: 'photos',
          render: (photos: string[]) => (
            <Tooltip title={photos.length > 3 ? `+${photos.length - 3} more` : ''}>
              <div>
                {photos.slice(0, 3).map((photo, index) => (
                  <img
                    key={index}
                    src={photo}
                    alt={`${index}`}
                    style={{ width: 32, height: 32, marginRight: 4, borderRadius: '4px' }}
                  />
                ))}
              </div>
            </Tooltip>
          ),
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (record: any) => (
            <Dropdown menu={{ items }}>
              <EllipsisOutlined className={styles.moreOptions} />
            </Dropdown>
          ),
        },
      ]
    case 'facebook':
      // Facebook columns
      break
    case 'twitter':
      // Twitter columns
      break
    case 'linkedin':
      return [
        {
          title: 'Profile Photo',
          dataIndex: 'profilePhoto',
          key: 'profilePhoto',
          width: 120,
          render: (text: string) => (
            <img
              src={convrt}
              alt='profile'
              style={{ width: 32, height: 32, borderRadius: '50%', marginLeft: '25px' }}
            />
          ),
        },
        {
          title: 'Username',
          dataIndex: 'handle',
          key: 'handle',
          width: 175,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width: 175,
        },
        {
          title: 'Full name',
          dataIndex: 'name',
          key: 'name',
          width: 175,
        },
        {
          title: 'Account Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status: string) => (
            <Tag className={status === 'Connected' ? styles.statusConnected : styles.statusDisconnected}>{status}</Tag>
          ),
        },
      ]
    case 'discord':
      // TikTok columns
      break
    default:
      return []
  }
}

export default getColumns

const CustomDropdown = ({ data, isLoadingAccount, onEditAccount, setPhoneNumber, setOpenQrcodPage }: any) => {
  const [open, setOpen] = useState(false)

  const isDisabled = data?.status === 'Disconnected'

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <div>
          <span>Edit Account Details</span>
          {isLoadingAccount && (
            <Spin
              style={{ marginLeft: '6px' }}
              indicator={<LoadingOutlined style={{ fontSize: 16, color: 'black' }} spin />}
            />
          )}
        </div>
      ),
      disabled: isDisabled,
    },
    { key: 'getCode', label: 'Get Login Code', disabled: isDisabled },
  ]

  useEffect(() => {
    if (!isLoadingAccount) {
      setOpen(false)
    }
  }, [isLoadingAccount])

  return (
    <Dropdown
      open={open}
      menu={{
        items,
        onMouseLeave: (): void => setOpen(false),
        onClick: async (e: any) => {
          switch (e?.key) {
            case 'edit':
              if (!isDisabled) {
                try {
                  onEditAccount(data)
                  break
                } catch (e) {
                  console.error('ERROR: ', e)
                  break
                }
              }
              break
            case 'getCode':
              if (!isDisabled) {
                try {
                  setOpen(false)
                  setPhoneNumber({ area_code: data.area_code, number: data.number })
                  setOpenQrcodPage(data)
                  break
                } catch (e) {
                  console.error('ERROR: ', e)
                  break
                }
              }
              break
            default:
              break
          }
        },
      }}>
      <EllipsisOutlined
        onClick={() => setOpen(!open)}
        className={styles.moreOptions}
        style={{ fontSize: '22px', color: '#5E6C84' }}
      />
    </Dropdown>
  )
}
