import { useLocation } from 'react-router-dom'
import { Input, Layout, Select, Dropdown, Button, Space, Popover } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import vSign from 'common/assets/svg/vSign.svg'
import points from 'common/assets/svg/3_points.svg'
import filter_icon from 'common/assets/svg/filter-icon.svg'

import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { countriesWithPhonePrefix } from 'common/static-data/countires'
import MonthSwitcher from 'features/events/components/MonthSwitcher/MonthSwitcher'
import { SET_EVENTS_FILTERS } from 'features/events/state/slice/events.slice'
import _ from 'lodash'
import { ChangeEvent, useEffect, useState, useLayoutEffect } from 'react'
import {
  SET_FILTER,
  SET_SAVE_FILTER_MODAL,
  SET_IS_FILTER_CHANGED,
  SET_LAST_ACTION_PAGE,
  SET_APPLY_FILTERS_KEY,
} from '../../../features/intent-signals/state/slice/companiesSlice'

import { SET_LAST_ACTION_PAGE_CONTACT } from '../../../features/contacts/state/slice/contactsSlice'

import { useActiveFilters } from '../../hooks/useFilters'
import { removeEmpty } from '../../utils/objectToValues'
import './TopBar.scss'
import {
  useLazyGetSaveFiltersQuery,
  useLazyDeleteSaveFiltersQuery,
} from '../../../features/intent-signals/state/api/CompaniesApi'
import { get, post } from 'common/api/axios'
import TrialBanner from '../TrialBanner/TrialBanner'
import { useAuth } from 'common/hooks/useAuth.hooks'

const { Header } = Layout

export const TopBar = () => {
  const [refetchGetSaveFilters] = useLazyGetSaveFiltersQuery()
  const [refechDeleteSaveFilters] = useLazyDeleteSaveFiltersQuery()
  const { userDetails, isBetaOrTrialUser, isPaidTrialUser } = useAuth()

  const [deleteSaveFilterRow, setDeleteSaveFilterRow] = useState(false)
  const [filterSaveRows, setFilterSaveRows] = useState([])

  const { filter, apply_filters_key } = useAppSelector((state) => state.companies)
  const { filters } = useAppSelector((state) => state.events)
  const { filters: eventsFilters } = useAppSelector((state) => state.events)
  const { save_filter_properties } = useAppSelector((state) => state.companies)

  const [activeFilters, setActiveFilters] = useActiveFilters()
  const [searchValue, setSearchValue] = useState('')
  const [daysLeft, setDaysLeft] = useState<any>()
  const [subscriptionCreatedAtLocalStorage, setSubscriptionCreatedAtLocalStorage] = useState(null)
  const [subscriptionDaysLocalStorage, setSubscriptionDaysLocalStorage] = useState(null)
  const dispatch = useAppDispatch()

  const pageLookUp = {
    home: 'Home',
    events: 'Events',
    contacts: 'Research',
    outreach: 'Outreach',
    exposedContacts: 'Exposed Contacts',
    companies: 'Research',
    settings: 'Profile Settings',
    company_admin: 'Company Admin Panel',
    checkout: 'Checkout',
  }

  const locationPathName = (useLocation().pathname.split('/')[1] as keyof typeof pageLookUp) || 'home'
  const eventsCountries = [{ label: 'All Countries', value: '' }, ...countriesWithPhonePrefix]

  const updateTrialDays = () => {
    const currentDate = new Date()
    const createdSubscriptionDate = userDetails?.subscription_created_at
      ? new Date(userDetails.subscription_created_at)
      : subscriptionCreatedAtLocalStorage
      ? new Date(subscriptionCreatedAtLocalStorage)
      : null
    const subscriptionDays = userDetails?.subscription_days || subscriptionDaysLocalStorage
    if (createdSubscriptionDate !== null && typeof subscriptionDays === 'number') {
      const timeDifference = currentDate.getTime() - createdSubscriptionDate.getTime()
      const daysPassed = timeDifference / (1000 * 60 * 60 * 24)
      setDaysLeft(Math.trunc(subscriptionDays - daysPassed))
    } else {
      setDaysLeft('N/A')
    }
  }
  const handleSearch = async (event: any) => {
    if (13 === event.keyCode) {
      const filterCopy = JSON.parse(JSON.stringify(filter))
      if (locationPathName === 'contacts') {
        let data: any = {}
        data['nameOfContact_' + Math.random()] = event.target.value
        _.set(filterCopy, 'searchBarContact', filter?.searchBarContact ? [...filter?.searchBarContact, data] : [data])
      } else if (locationPathName === 'companies') {
        let data: any = {}
        data['nameOfCompany_' + Math.random()] = event.target.value
        _.set(filterCopy, 'searchBarCompany', filter?.searchBarCompany ? [...filter?.searchBarCompany, data] : [data])
      }

      dispatch(SET_APPLY_FILTERS_KEY(apply_filters_key + 1))
      dispatch(SET_FILTER(removeEmpty(filterCopy)))
      dispatch(SET_IS_FILTER_CHANGED(true))
      dispatch(SET_LAST_ACTION_PAGE(1))
      dispatch(SET_LAST_ACTION_PAGE_CONTACT(1))

      setActiveFilters(filter)

      if (userDetails?.has_filtered === false) {
        const userData = { ...userDetails }
        userData.has_filtered = true
        localStorage.setItem('user', JSON.stringify(userData))
      }
    }
  }

  const onEventsSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(SET_EVENTS_FILTERS({ ...filters, [name]: value }))
  }

  const onSelect = (value: string) => {
    dispatch(SET_EVENTS_FILTERS({ ...filters, country: value }))
  }

  useEffect(() => {
    if (!isBetaOrTrialUser && !isPaidTrialUser) {
      return
    }
    // Temp solution for getting the subscription_days / subscription_created_at
    const updateUserLocalStorage = async () => {
      const user = localStorage.getItem('user') || '{}'
      const userData = JSON.parse(user)
      if (!userData.subscription_days || !userData.subscription_created_at) {
        try {
          // change to redux later on
          const response = await get(`/user/userDetails`)
          userData.subscription_days = response?.data?.subscription_days
          userData.subscription_created_at = response?.data?.subscription_created_at
          localStorage.setItem('user', JSON.stringify(userData))
          setSubscriptionDaysLocalStorage(response?.data?.subscription_days)
          setSubscriptionCreatedAtLocalStorage(response?.data?.subscription_created_at)
        } catch (error) {
          console.error('Error fetching data: ', error)
        }
      }
    }
    updateUserLocalStorage()
    updateTrialDays()
  }, [subscriptionDaysLocalStorage, subscriptionCreatedAtLocalStorage])

  useEffect(() => {
    if (filter?.searchBarCompany?.nameOfCompany) {
      setSearchValue(filter?.searchBarCompany?.nameOfCompany)
    } else if (filter?.searchBarContact?.nameOfContact) {
      setSearchValue(filter?.searchBarContact?.nameOfContact)
    } else {
      setSearchValue('')
    }
    document.getElementById('searchBox')?.addEventListener('keydown', handleSearch)
    return () => {
      document.getElementById('searchBox')?.removeEventListener('keydown', handleSearch)
    }
  }, [filter])

  const [openMenu, setOpenMenu] = useState(false)
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false)
  useLayoutEffect(() => {
    const fetchDataCompanyLists = async () => {
      const filterData = await refetchGetSaveFilters()
      setFilterSaveRows(filterData.data)
    }

    fetchDataCompanyLists()
  }, [deleteSaveFilterRow])

  const items =
    filterSaveRows?.length !== 0
      ? filterSaveRows?.map((data: any) => ({
          key: data.id,
          label: (
            <div key={data.id} className='menu_item_parent'>
              <span
                className='menu_item_parent-flex-grow'
                onClick={() => {
                  let tempFilter = { ...data.filter }
                  if (locationPathName === 'contacts') {
                    if (data?.filter?.companyList) {
                      tempFilter.contactsList = tempFilter.companyList
                      delete tempFilter?.companyList
                    }
                    if (data?.filter?.companyExcludeList) {
                      tempFilter.contactsExcludeList = tempFilter.companyExcludeList
                      delete tempFilter?.companyExcludeList
                    }
                    if (data?.filter?.companyIncludeList) {
                      tempFilter.contactsIncludeList = tempFilter.companyIncludeList
                      delete tempFilter?.companyIncludeList
                    }
                    if (data?.filter?.country) {
                      tempFilter.contactsCountry = tempFilter.country
                      delete tempFilter.country
                    }
                    if (data?.filter?.searchBarCompany) {
                      delete tempFilter?.searchBarCompany
                    }
                  }
                  if (locationPathName === 'companies') {
                    if (data?.filter?.contactsList) {
                      tempFilter.companyList = tempFilter.contactsList
                      delete tempFilter?.contactsList
                    }
                    if (data?.filter?.contactsCountry) {
                      tempFilter.country = tempFilter.contactsCountry
                      delete tempFilter.contactsCountry
                    }
                    if (data?.filter?.departments) {
                      delete tempFilter?.departments
                    }
                    if (data?.filter?.jobTitle) {
                      delete tempFilter?.jobTitle
                    }
                    if (data?.filter?.managementLevel) {
                      delete tempFilter?.managementLevel
                    }
                    if (data?.filter?.companiesName) {
                      delete tempFilter?.companiesName
                    }
                    if (data?.filter?.searchBarContact) {
                      delete tempFilter?.searchBarContact
                    }
                  }

                  setActiveFilters(tempFilter)
                  dispatch(SET_FILTER(removeEmpty(tempFilter)))
                  setOpenMenu(false)

                  const log = async () => {
                    await post(`/companies/log/saved-filter`, { label: data.filter_name })
                  }

                  log()
                }}>
                {data.filter_name}
              </span>
              <Popover
                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                placement='leftTop'
                zIndex={1050}
                destroyTooltipOnHide={isOpenSubMenu}
                trigger={['click']}
                content={
                  <div className='popover_parent_edit_delete'>
                    <div
                      className='popover_parent_edit_delete-item_edit'
                      onClick={() => {
                        setOpenMenu(false)
                        dispatch(
                          SET_SAVE_FILTER_MODAL({
                            open_modal: true,
                            filter_name: data.filter_name,
                            filters: [data.filter],
                            filter_id: data.id,
                            open_from: 'topBar',
                            isSaved: false,
                          }),
                        )
                      }}>
                      {'Edit'}
                    </div>
                    <div
                      className='popover_parent_edit_delete-item_delete'
                      onClick={() => {
                        setOpenMenu(false)
                        const fetchDeleteSaveFiltersRow = async () => {
                          await refechDeleteSaveFilters({ save_filters_row_id: data.id })
                        }
                        fetchDeleteSaveFiltersRow()
                        setDeleteSaveFilterRow(true)
                      }}>
                      {'Delete'}
                    </div>
                  </div>
                }>
                <span
                  className='menu_item_parent-dot'
                  onClick={() => {
                    setIsOpenSubMenu(true)
                  }}>
                  <img src={points} alt='' style={{ margin: '0px 5px' }} />
                </span>
              </Popover>
            </div>
          ),
        }))
      : [
          {
            key: 'empty',
            label: (
              <div className='c-empty-saved-filters'>
                <img src={filter_icon} alt='' />
                <p>Save Filters</p>
                <span>
                  Save your favorite filters for future
                  <br />
                  use and easy access
                </span>
                {Object.keys(activeFilters || {})?.length !== 0 && (
                  <Button
                    type='text'
                    onClick={() => {
                      dispatch(
                        SET_SAVE_FILTER_MODAL({
                          open_modal: true,
                          filters: [activeFilters],
                          open_from: 'filterBar',
                          isSaved: false,
                        }),
                      )
                    }}>
                    {'Save Current Filters'}
                  </Button>
                )}
              </div>
            ),
          },
        ]

  const handleClick = () => {
    const fetchDataCompanyLists = async () => {
      const filterData = await refetchGetSaveFilters()
      setFilterSaveRows(filterData?.data || [])
      setOpenMenu(true)
    }

    fetchDataCompanyLists()
  }

  document.documentElement.style.setProperty(
    '--item-hover-background',
    filterSaveRows?.length === 0 ? 'white' : `#fafafa`,
  )

  return (
    <>
      {(isBetaOrTrialUser || isPaidTrialUser) && locationPathName !== 'checkout' && <TrialBanner daysLeft={daysLeft} />}
      <Header className='c-topbar'>
        <span className='c-topbar-title'>{pageLookUp[locationPathName] || ''}</span>
        <span className='c-topbar-filter'>
          {(locationPathName === 'contacts' || locationPathName === 'companies') && (
            <Input
              id='searchBox'
              placeholder={
                locationPathName === 'contacts'
                  ? 'Search People, Tags, Job Titles'
                  : 'Search Company name, domain or URL'
              }
              className='peopleSearchBar'
              onKeyDown={handleSearch}
              onChange={(event: any) => {
                const value = event.target.value
                // if (/^[a-zA-Z0-9.,|&\/-]+$/.test(value) || value === '') {
                if (/^[^`']+$/g.test(value) || value === '') {
                  setSearchValue(value)
                }
              }}
              value={searchValue}
            />
          )}
          {(locationPathName === 'contacts' || locationPathName === 'companies') && (
            <div className='parent_dropdown'>
              <Dropdown
                overlayClassName='savedFiltersDropdown'
                menu={{ items }}
                placement='bottomRight'
                destroyPopupOnHide={openMenu}
                trigger={['click']}
                open={openMenu}
                onOpenChange={(newOpen: boolean) => {
                  setOpenMenu(newOpen)
                }}>
                <Popover
                  trigger={['click']}
                  open={save_filter_properties.isSaved}
                  content={
                    <div>
                      <img src={vSign} alt='' style={{ margin: '0px 5px' }} />
                      <span>{'New filters group is saved'}</span>
                    </div>
                  }
                  placement='bottomRight'>
                  <Button className='button' onClick={handleClick}>
                    <Space>
                      {'Saved Filters'}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Popover>
              </Dropdown>
            </div>
          )}
          {/* To remove for events functionality */}
          {locationPathName === 'events' && (
            <>
              <MonthSwitcher />{' '}
              <div className='flex gap-2 align-items-center'>
                <Select
                  className='w-9'
                  showSearch
                  placeholder='All Countries'
                  onChange={onSelect}
                  value={eventsFilters?.country}
                  options={eventsCountries}
                />
                <Input id='searchBox' name='name' placeholder='Search Events' allowClear onChange={onEventsSearch} />
              </div>
            </>
          )}
        </span>
      </Header>
    </>
  )
}
